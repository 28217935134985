<template>
  <div></div>
</template>

<script>
// import { CLIENTS_ENDPOINT } from "../util/constants.js";
export default {
  name: "Home",
  components: {},
  mounted() {
    this.$router.push("/feltoltesek");
  },
  data() {
    return {
      clients: [],
    };
  },
};
</script>
